import AudioApi from '@phoenix7dev/audio-api';
import i18n from 'i18next';

import { ISongs, mappedAudioSprites } from '../config';
import {
  BetBonusReward,
  bonusesId,
  EventTypes,
  GameMode,
  ISettledBet,
  IUserBalance,
  MessageBannerTypes,
  ReelSet,
  reelSets,
  UserBonus,
  UserBonusStatus,
} from '../global.d';
import {
  client,
  getUserGql,
  isStoppedGql,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql';
import { setBetAmount, setBottomContainerTotalWin, setBrokenPickem, setCoinAmount, setCoinValue, setFreeRoundsBonus, setFreeRoundsTotalWin, setIsErrorMessage, setIsSlotBusy, setIsSpinInProgress, setIsTimeoutErrorMessage, setIsTransitionStarted, setUserBalance } from '../gql/cache';
import {
  getLastBet,
  getUserActiveBonuses,
  isBuyFeatureEnabled,
  isFreeRoundBonusMode,
  isFreeSpinMode,
  isRegularMode,
  normalizeCoins,
  showCurrency,
} from '../utils';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  eventManager,
  FREE_SPINS_TIME_OUT_BANNER,
  REELS_AMOUNT,
  SlotMachineState,
} from './config';
import type { ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import {
  btnStyle,
  subtitleStyle,
  titleStyle,
} from './messageBanner/textStyles';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import type Slot from './slot/slot';
import type SpinAnimation from './spin/spin';
import StickyWildsContainer from './stickyWilds/stickyWildsContainer';
import TintContainer from './tint/tintContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';
import type { Application } from 'pixi.js';
import AutoplayBtn from './controlButtons/autoplayBtn';
import MenuBtn from './controlButtons/menuBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import SpinBtn from './controlButtons/spinBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import PhoenixAnticipation from './phoenixAnticipation/phoenixAnticipation';
import { getAnticipationReelId, getScatterCount, getSpinResult } from '../utils/math';
import { totalCostTextStyle } from './buyFeature/textStyles';
import { formatNumber } from '@phoenix7dev/utils-fe';
import { ReelSetType } from '../gql/d';
import { FreeRoundsEndPopup } from './popups/freeRoundsPopupEnd';
import { FreeRoundsPopup } from './popups/freeRoundsPopup';
import { PopupController } from './popups/PopupController';
import { PopupTypes } from './popups/d';

class SlotMachine {
  private readonly application: Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: Application,
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      slotData,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public gameView: GameView;

  public reelsContainer: ReelsContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public stickyWildsContainer: StickyWildsContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public infoBtn: InfoBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private constructor(
    application: Application,
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initPixiDebugExtension();
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    const isLastBetPresent = setUserLastBetResult().id;
    let startPosition = slotData.settings.startPosition;
    let reelSet = slotData.reels.find(
      (reel) => reel.type === ReelSetType.DEFAULT,
    ) as ReelSet;

    if (isLastBetPresent) {
      const newReelSet = slotData.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!;
      if (newReelSet.layout.length) {
        reelSet = newReelSet;
        startPosition = setUserLastBetResult().result.reelPositions
      }
    }
    setReelSetId(reelSet.id);
    setPrevReelsPosition(startPosition.slice(0, 5));
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.miniPayTableContainer = new MiniPayTableContainer(
      slotData.icons,
      this.getSlotById.bind(this),
    );
    this.stickyWildsContainer = new StickyWildsContainer();
    this.miniPayTableContainer.setSpinResult(
      getSpinResult({
        reelPositions: startPosition.slice(0, 5),
        reelSet,
        icons: slotData.icons,
      }),
    );
    this.gameView = this.initGameView(slotData);
    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();
    const phoenixAnticipation = new PhoenixAnticipation();

    //popups
    const freeRoundsPopup = new FreeRoundsPopup();
    const freeRoundsEndPopup = new FreeRoundsEndPopup();

    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS, freeRoundsPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS_END, freeRoundsEndPopup);
    this.initPixiLayers();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.infoBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.application.stage.addChild(freeRoundsPopup);
    this.application.stage.addChild(freeRoundsEndPopup);
    this.application.stage.addChild(phoenixAnticipation);
    if (setBrokenGame() && setGameMode() === GameMode.FREE_SPINS) {
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setBottomContainerTotalWin(),
      );
      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t('freeSpinsTitle'),
        spins: setCurrentBonus().rounds,
        currentSpin: setCurrentBonus().currentRound,
      });
      if (setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      }
    }
  }

  private initPixiDebugExtension(): void {
    (globalThis as unknown as { __PIXI_APP__: unknown }).__PIXI_APP__ = this.application;
  }


  private getLastBetReelPositions(): number[] {
    // If last bet was PICKEM shot query we will have no postions so manually put [0, 0, 0, 0, 0] reels value for it
    return setUserLastBetResult().result.reelPositions.length
      ? setUserLastBetResult().result.reelPositions
      : [0, 0, 0, 0, 0];
  }

  private async onBrokenGame(): Promise<void> {
    setFreeSpinsTotalWin(0);
    const gameMode = this.getGameModeByBonusId(setCurrentBonus().bonusId);
    if (gameMode === GameMode.FREE_ROUND_BONUS) {
      this.startFreeroundBonus();
    } else {
      setIsFreeSpinsWin(true);
      setReelSetId(setCurrentBonus().reelSetId);
      eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
        mode: gameMode,
      });
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
    }
    setGameMode(gameMode);
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    this.setState(SlotMachineState.IDLE);
  }


  private getGameModeByBonusId(bonusId: string): GameMode {
    if (bonusId === bonusesId[GameMode.PICKEM]) return GameMode.PICKEM;
    if (bonusId === bonusesId[GameMode.FREE_SPINS]) return GameMode.FREE_SPINS;
    if (bonusId === bonusesId[GameMode.FREE_ROUND_BONUS]) return GameMode.FREE_ROUND_BONUS;
    return GameMode.REGULAR;
  }

  private initPixiLayers() {
    this.application.stage.addChild(
      new Background(),
      new Backdrop(
        EventTypes.SHOW_BACKDROP,
        EventTypes.HIDE_BACKDROP,
      ),
      this.initSafeArea(),
      new BottomContainer(),
      new FadeArea(),
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotData: ISlotData): GameView {
    const gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      linesContainer: new LinesContainer(slotData.lines),
      reelsContainer: this.reelsContainer,
      tintContainer: new TintContainer(),
      winLabelContainer: new WinLabelContainer(),
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
      stickyWildsContainer: this.stickyWildsContainer,
    });

    gameView.interactive = true;
    gameView.on('mousedown', () => this.skipAnimations());
    gameView.on('touchstart', () => this.skipAnimations());

    if (isBuyFeatureEnabled(slotData.clientSettings.features)) {
      this.initBuyFeature(slotData.lines, gameView);
    }

    return gameView;
  }

  private initBuyFeature(lines: number[][], view: GameView): void {
    view.addChild(
      new BuyFeatureBtn(),
      new Backdrop(
        EventTypes.SHOW_BACKDROP,
        EventTypes.HIDE_BACKDROP,
      ),
      new BuyFeaturePopup(lines),
      new BuyFeaturePopupConfirm(),
    );
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
      if (setBrokenBuyFeature()) {
        setTimeout(() => {
          eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
          setBrokenBuyFeature(false);
        });
      }
      if (setBrokenGame()) {
        this.onBrokenGame();
      }
      if (setBrokenPickem()) {
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      }
    });
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.END_RETRIGGER_FEATURE,
      this.onRetriggerEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_BUY_FEATURE_ROUND,
      this.startBuyFeature.bind(this),
    );
    eventManager.on(EventTypes.FREE_ROUND_BONUS_EXPIRED, () => {
      setBrokenGame(false);
      setIsTransitionStarted(true);
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelPositions: setUserLastBetResult().result.reelPositions,
        reelSetId: setUserLastBetResult().reelSetId,
      });
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    endBonus?: boolean;
  }) {
    setGameMode(settings.mode);
    setReelSetId(settings.reelSetId);

    const reelSet = setSlotConfig().reels.find(
      (reels) => reels.id === settings.reelSetId,
    );

    if (settings.mode !== GameMode.PICKEM) {
      const spinResult = getSpinResult({
        reelPositions: settings.reelPositions.slice(0, 5),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });

      this.miniPayTableContainer.setSpinResult(spinResult);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.id === settings.reelSetId,
        ),
        reelPositions: settings.reelPositions,
      });
    }
    setPrevReelsPosition(settings.reelPositions);
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonusId('');
      if (this.nextResult) {
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
        setUserBalance({ ...setUserBalance(), balance: this.nextResult?.balance.settled! });
      } else {
        client
          .query<{ user: IUserBalance }>({
            query: getUserGql,
            fetchPolicy: 'network-only',
          })
          .then((userBalance) => {
            setUserBalance(userBalance.data.user);
            eventManager.emit(EventTypes.UPDATE_USER_BALANCE, userBalance.data.user.balance);
          });
      }
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setBottomContainerTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        }),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      eventManager.emit(
        EventTypes.DISABLE_BUY_FEATURE_BTN,
        setIsContinueAutoSpinsAfterFeature(),
      );
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, false);
      this.setState(SlotMachineState.IDLE);

      // AudioApi.stop({ type: ISongs.FreeSpinBGM_Intro });
      AudioApi.stop({ type: ISongs.FreeSpinBGM_Loop });
      AudioApi.play({ type: ISongs.BaseGameBGM_Base });
      AudioApi.play({ type: ISongs.BaseGameBGM_Melo, volume: 0 });
    } else if (settings.mode === GameMode.PICKEM) {
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setBottomContainerTotalWin(),
      );

      AudioApi.stop({ type: ISongs.BaseGameBGM_Base });
      AudioApi.stop({ type: ISongs.BaseGameBGM_Melo });

      AudioApi.play({ type: ISongs.PickemBonusBGM_Loop });
    } else if (isFreeSpinMode(settings.mode)) {
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
      eventManager.emit(EventTypes.SET_WIN_LABEL_TEXT, 'total win');
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setBottomContainerTotalWin(),
      );
      setCurrentBonusId(setCurrentBonus().id);

      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t('freeSpinsTitle'),
        spins: setCurrentBonus().rounds,
        currentSpin: setCurrentBonus().currentRound,
      });

      this.setState(SlotMachineState.IDLE);

      // bgm
      // const introDelay = Tween.createDelayAnimation(
      //   mappedAudioSprites[ISongs.FreeSpinBGM_Intro].duration,
      // );
      // AudioApi.stop({ type: ISongs.BaseGameBGM_Base });
      // AudioApi.stop({ type: ISongs.BaseGameBGM_Melo });
      // AudioApi.play({ type: ISongs.FreeSpinBGM_Intro });
      // introDelay.addOnComplete(() => {
      //   const { volume } = AudioApi.getSoundByKey(ISongs.FreeSpinBGM_Intro);
      AudioApi.stop({ type: ISongs.PickemBonusBGM_Loop });
      AudioApi.play({ type: ISongs.FreeSpinBGM_Loop });
      // });
      // introDelay.start();
    } else if (settings.mode === GameMode.BUY_FEATURE) {
      // this.spin(false);
    } else if (settings.mode === GameMode.FREE_ROUND_BONUS) {
      if (!settings?.endBonus && setFreeRoundsBonus().isActive) {
        setCurrentBonus({ ...setFreeRoundsBonus() });
      }
      setIsFreeSpinsWin(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
      setCoinValue(setCurrentBonus().coinValue);
      setCoinAmount(setCurrentBonus().coinAmount);
      setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
      eventManager.emit(EventTypes.UPDATE_BET);
      setIsSlotBusy(false);

      // AudioApi.stop({ type: ISongs.FreeSpinBGM_Intro });
      AudioApi.stop({ type: ISongs.FreeSpinBGM_Loop });
      AudioApi.play({ type: ISongs.BaseGameBGM_Base });
      AudioApi.play({ type: ISongs.BaseGameBGM_Melo, volume: 0 });

      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      console.log(setBottomContainerTotalWin());
      if (setBottomContainerTotalWin() > 0) {
        setTimeout(() => {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
        }, 0);
      }
      eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
        this.setState(SlotMachineState.IDLE);
      });
      if (settings?.endBonus && setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound > 0) {
        setCurrentBonus({ ...setCurrentBonus(), rounds: setFreeRoundsBonus().rounds, currentRound: setFreeRoundsBonus().currentRound });
        eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
        setStressful({
          show: true,
          type: 'network',
          message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
          callback: () => {
            setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
            setCurrentBonus({ ...setCurrentBonus(), isActive: false });
            PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END, { isExpired: true });
          },
        });
      } else if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
        this.setState(SlotMachineState.IDLE);
      } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
        PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
      } else {
        setCurrentBonus({ ...setCurrentBonus(), isActive: true });
        eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
      }
    }
  }

  private startBuyFeature(): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.BUY_FEATURE,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.BUY_FEATURE],
    });
  }

  private startPickem(): void {
    this.setState(SlotMachineState.PICKEM);
    setIsFreeSpinsWin(true);
    const handlePickemBanner = () => {
      AudioApi.playAsync({ type: ISongs.PickemBonusEntrancePopup, fadeOutImmediately: [ISongs.BaseGameBGM_Melo, ISongs.BaseGameBGM_Base, ISongs.PickemBonusBGM_Loop] }).then(() => {
        AudioApi.fadeIn(0, ISongs.PickemBonusBGM_Loop);
      });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        bannerBg: 'pickem_bonus',
        type: MessageBannerTypes.Pickem,
        title: {
          text: 'pickemMessageBannerText1',
          styles: titleStyle,
        },
        subtitle: {
          text: 'pickemMessageBannerText2',
          styles: subtitleStyle,
        },
        btn: {
          text: 'pickemMessageBannerBtnText',
          styles: btnStyle,
        },
        callback: () => {
          if (AudioApi.isPlaying(ISongs.PickemBonusEntrancePopup)) {
            AudioApi.fadeOut(1000, ISongs.PickemBonusEntrancePopup);
            AudioApi.play({ type: ISongs.PickemBonusBGM_Loop, volume: 0 })
            AudioApi.fadeIn(1000, ISongs.PickemBonusBGM_Loop);
          }
        }
      });
    };
    setIsTransitionStarted(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.PICKEM,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.PICKEM],
      callback: handlePickemBanner,
    });
  }

  // todo implement start free spins
  private async endFreeSpins(): Promise<void> {
    const slotData = setSlotConfig();
    const isFreeRoundsBonus = setFreeRoundsBonus().isActive;
    let frbBonus: UserBonus;
    if (isFreeRoundsBonus) {
      setBottomContainerTotalWin(setFreeSpinsTotalWin() + setFreeRoundsTotalWin());
      const bonuses = await getUserActiveBonuses();
      // const bonuses: UserBonus = {
      //   ...(setCurrentBonus() as UserBonus),
      //   isActive: true,
      //   gameMode: GameMode.FREE_ROUND_BONUS,
      //   currentRound: 0,
      //   rounds: 5,
      //   totalWinAmount: 0,
      //   isFreeBet: true,
      //   coinAmount: 1,
      //   coinValue: 100,
      //   id: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
      //   bonusId: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
      // };
      frbBonus = bonuses.data.userBonuses.find(
        (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
      ) as UserBonus;
    }

    await getLastBet(false);

    const reelPositions = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioApi.playAsync({
      type: ISongs.FreeSpinExitPopup,
      fadeOutImmediately: [ISongs.FreeSpinBGM_Loop],
    }).then(() => {
      if (!AudioApi.isPlaying(ISongs.BaseGameBGM_Base)) {
        AudioApi.fadeIn(1000, ISongs.FreeSpinBGM_Loop);
      }
    });
    const callback = () => {
      setIsTransitionStarted(true);
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: isFreeRoundsBonus ? GameMode.FREE_ROUND_BONUS : GameMode.REGULAR,
        reelSetId: setUserLastBetResult().reelSetId,
        reelPositions,
        endBonus: !frbBonus
      });
      if (AudioApi.isPlaying(ISongs.FreeSpinExitPopup)) {
        AudioApi.fadeOut(1000, ISongs.FreeSpinExitPopup);
        setTimeout(() => {
          AudioApi.fadeOut(0, ISongs.FreeSpinExitPopup);
        }, 1000);
      }
      AudioApi.fadeOut(1000, ISongs.FreeSpinBGM_Loop);
      setTimeout(() => {
        AudioApi.fadeIn(1000, ISongs.BaseGameBGM_Base);
      }, 1000);
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        bannerBg: 'you_won_mode',
        type: MessageBannerTypes.Total,
        title: {
          text: 'freeSpinsMessageBannerWinText1',
          styles: titleStyle,
        },
        subtitle: {
          text: 'freeSpinsMessageBannerWinText2',
          styles: { ...totalCostTextStyle, fontSize: 52 },
        },
        value: {
          text: formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
          styles: { ...subtitleStyle, fontSize: 78 },
        },
        btn: {
          text: 'freeSpinsMessageBannerWinBtnText',
          styles: btnStyle,
        },

        preventDefaultDestroy: true,
        callback,
      });
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        bannerBg: 'you_won_mode',
        type: MessageBannerTypes.Total,
        title: {
          text: 'freeSpinsMessageBannerWinText1',
          styles: titleStyle,
        },
        subtitle: {
          text: 'freeSpinsMessageBannerWinText2',
          styles: { ...totalCostTextStyle, fontSize: 52 },
        },
        value: {
          text: formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
          styles: { ...subtitleStyle, fontSize: 78 },
        },
        btn: {
          text: 'freeSpinsMessageBannerWinBtnText',
          styles: btnStyle,
        },
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
      });
    }
    setBrokenGame(false);
  }

  private endFreeRoundBonus(): void {
    setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);

    eventManager.once(EventTypes.END_FREE_ROUND_BONUS, () => {
      setBrokenGame(false);
      PopupController.the.closeCurrentPopup();
      setIsTransitionStarted(true);
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelPositions: setUserLastBetResult().result.reelPositions,
        reelSetId: setUserLastBetResult().reelSetId,
      });
    });
  }

  private startFreeroundBonus(): void {
    setIsFreeSpinsWin(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    setCoinValue(setCurrentBonus().coinValue);
    setCoinAmount(setCurrentBonus().coinAmount);
    setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
    eventManager.emit(EventTypes.UPDATE_BET);
    setIsSlotBusy(false);

    // AudioApi.stop({ type: ISongs.FreeSpinBGM_Intro });
    AudioApi.stop({ type: ISongs.FreeSpinBGM_Loop });
    AudioApi.play({ type: ISongs.BaseGameBGM_Base });
    AudioApi.play({ type: ISongs.BaseGameBGM_Melo, volume: 0 });

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setBottomContainerTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }, 0);
    }
    eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
      this.setState(SlotMachineState.IDLE);
    });
    if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
      this.setState(SlotMachineState.IDLE);
    } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
    } else {
      setCurrentBonus({ ...setCurrentBonus(), isActive: true });
      eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    }
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0]!.spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      current,
      total,
    );
  }

  private updateFreeRoundsAmount(current: number, total: number): void {
    eventManager.emit(
      EventTypes.UPDATE_FREE_ROUNDS_LEFT,
      total - current
    );
  }

  private dynamicReelSetChange(_nextResult: ISettledBet): void {
    if (setReelSetId() !== this.nextResult?.bet.reelSetId) {
      setReelSetId(this.nextResult?.bet.reelSetId);

      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.id === setReelSetId(),
        ),
        reelPositions: this.nextResult?.bet.result.reelPositions,
      });
    }
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.isReadyForStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        this.removeErrorHandler();
        this.dynamicReelSetChange(this.nextResult);
        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          this.nextResult.bet.result.reelPositions,
          getScatterCount(this.nextResult.bet.result.spinResult),
          getAnticipationReelId(this.nextResult.bet.result.spinResult),
        );
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(
        !isFreeSpinMode(setGameMode()) && !!isTurboSpin,
      );
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const mode = setGameMode();
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      let spinAnimation: SpinAnimation;
      if (mode === GameMode.BUY_FEATURE) {
        spinAnimation = reel.createBuyFeatureSpinAnimation(isTurboSpin);
      } else {
        spinAnimation = reel.createSpinAnimation(isTurboSpin);
      }
      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange(this.nextResult);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              getScatterCount(this.nextResult.bet.result.spinResult),
              getAnticipationReelId(this.nextResult.bet.result.spinResult),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() =>
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
          );
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private isFreeSpins(): boolean {
    const reward = this.nextResult?.rewards.find(
      // eslint-disable-next-line no-underscore-dangle
      (reward) => reward.__typename === 'BetBonusReward',
    ) as BetBonusReward;
    return reward?.userBonus?.bonusId === bonusesId[GameMode.PICKEM];
  }

  private onCountUpEnd(): void {
    const mode = setGameMode();

    if (this.isFreeSpins()) {
      if (mode === GameMode.BUY_FEATURE || mode === GameMode.REGULAR || mode === GameMode.FREE_ROUND_BONUS) {
        if (mode === GameMode.FREE_ROUND_BONUS) {
          setFreeRoundsBonus({ ...setCurrentBonus() });
          setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
          eventManager.emit(
            EventTypes.UPDATE_TOTAL_WIN_VALUE,
            setBottomContainerTotalWin()
          );
        } else {
          setBottomContainerTotalWin(this.nextResult!.bet.result.winCoinAmount);
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({ currency: setCurrency(), value: normalizeCoins(this.nextResult!.bet.result.winCoinAmount), showCurrency: showCurrency(setCurrency()) }),
          );
        }
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentBonus({
          ...this.nextResult!.bet.data.bonuses[0]!,
          isActive: true,
          currentRound: 0,
        });
        const reward = this.nextResult?.rewards[1] as BetBonusReward;
        setCurrentBonusId(
          reward.userBonusId,
        );
        if (reward.userBonus?.bonus?.reelSetId) {
          setUserLastBetResult({ ...setUserLastBetResult(), reelSetId: reward.userBonus.bonus.reelSetId })
        }
        setFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        this.startPickem();
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
        setUserBalance({ ...setUserBalance(), balance: this.nextResult?.balance.settled! });
      }
      if (isFreeSpinMode(mode)) {
        setFreeSpinsTotalWin(setFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setBottomContainerTotalWin(),
        );
      }
      if (mode === GameMode.FREE_ROUND_BONUS) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setFreeRoundsTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        setBottomContainerTotalWin(setBottomContainerTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        if (setBottomContainerTotalWin() > 0) {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
        }
      }
    }
    this.setState(SlotMachineState.IDLE);
  }

  private onRetriggerEnd(): void {
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions.slice(0, 5),
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    setUserLastBetResult(result.bet);
    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, 5));
    this.nextResult = result;
    if (isFreeSpinMode(setGameMode()) || isFreeRoundBonusMode(setGameMode())) {
      const bonus = setCurrentBonus();
      bonus.currentRound += 1;
      setCurrentBonus(bonus);
      if (isFreeSpinMode(setGameMode())) {
        this.updateFreeSpinsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
      }
      if (isFreeRoundBonusMode(setGameMode())) {
        this.updateFreeRoundsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
      }
    }
    if (!isFreeSpinMode(setGameMode())) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.placed,
      );
      setUserBalance({ ...setUserBalance(), balance: this.nextResult?.balance.placed! });
    }
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    if (setIsErrorMessage()) {
      this.setState(SlotMachineState.IDLE);
      setIsSpinInProgress(false);
      setIsErrorMessage(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    } else {
      this.isSpinInProgressCallback();
      this.miniPayTableContainer.setSpinResult(
        this.nextResult!.bet.result.spinResult,
      );
      this.stickyWildsContainer.setSpinResult(
        this.nextResult!.bet.data.features.multipliers,
      );
      this.setState(SlotMachineState.JINGLE);
    }

  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length -
        this.reelsContainer.reels[x]!.position +
        y -
        1) %
      this.reelsContainer.reels[x]!.data.length
    ] ?? null;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAYTABLE,
      isFreeSpinMode(setGameMode()) ? false : state === 0,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
      setIsFreeSpinsWin() ||
      setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinMode(setGameMode())) {
        if (
          setCurrentBonus().isActive &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      } else if (isFreeRoundBonusMode(setGameMode())) {
        if (setCurrentBonus().currentRound === setCurrentBonus().rounds || !setCurrentBonus().isActive) {
          this.endFreeRoundBonus();
          return;
        }
      } else if (isRegularMode(setGameMode()) && !setIsFreeSpinsWin()) {
        const betResult = this.nextResult!;
        // if (setGameMode() === GameMode.REGULAR) {
        // betResult.bet.data.bonuses.push({
        //   ...(setCurrentBonus() as UserBonus),
        //   isActive: true,
        //   gameMode: GameMode.FREE_ROUND_BONUS,
        //   currentRound: 0,
        //   rounds: 5,
        //   totalWinAmount: 0,
        //   isFreeBet: true,
        //   coinAmount: 1,
        //   coinValue: 100,
        //   id: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
        //   bonusId: '6a3a99d5-8988-488a-8af3-1d5ed72716f1',
        // });
        // }
        if (betResult) {
          const frbBonus = betResult?.bet.data.bonuses.find((e) => e.isFreeBet);
          if (frbBonus && frbBonus.status !== UserBonusStatus.SETTLED && setGameMode() === GameMode.REGULAR) {
            eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
            setCurrentBonus({
              ...frbBonus,
              gameMode: GameMode.FREE_ROUND_BONUS,
              rounds: frbBonus.rounds,
              isActive: true,
              currentRound: 0,
              coinAmount: frbBonus.coinAmount,
              coinValue: frbBonus.coinValue,
            });
            setBottomContainerTotalWin(0);
            setFreeRoundsTotalWin(0);
            setIsTransitionStarted(true);
            eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
              mode: GameMode.FREE_ROUND_BONUS,
              reelPositions: setUserLastBetResult().result.reelPositions,
              reelSetId: setUserLastBetResult().reelSetId,
              callback: this.startFreeroundBonus.bind(this)
            });
          }
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.isFreeSpins() && !isFreeSpinMode(setGameMode())) {
        const jingleDelay = Tween.createDelayAnimation(
          mappedAudioSprites[ISongs.FeatureTrigger].duration,
        );
        jingleDelay.addOnStart(() => {
          AudioApi.play({ type: ISongs.FeatureTrigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!,
          false,
        );
      } else {
        if (!isFreeSpinMode(GameMode.FREE_SPINS)) {
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
          setUserBalance({ ...setUserBalance(), balance: this.nextResult?.balance.settled! });
        }
        this.setState(SlotMachineState.IDLE);
      }
    }
  }
}

export default SlotMachine;
